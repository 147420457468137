<template>
  <div class="tab-items">
    <ul class="tab-list" :class="{ border: type, styleList: styleTab }">
      <li v-for="tab in tabItems" :key="tab.id" class="tab-item">
        <div
          :class="{
            active: tab.id === selected,
            border: type === 'border',
            styleTab: styleTab,
          }"
          class="tab-link second-title"
          @click="setTab(tab.id)"
        >
          <span v-if="tab.name" v-html="$t(tab.name, { value: tab.value })" />
          <div class="tab-image" v-if="tab.img">
            <img :src="tab.img" alt="" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    tabItems: {
      type: Array,
    },
    selected: {
      type: Number,
    },
    type: {
      type: String,
      required: false,
    },
    styleTab: {
      type: String,
      required: false,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit("setTab", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style: none;
}
.tab-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &.border {
    width: fit-content;
    gap: 0 42px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #c4c4c4;
    }
  }
}

.tab-image {
  text-align: center;
  img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.tab-link {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  &.border {
    text-transform: uppercase;
    color: #9f9f9f;
    padding: 0 0 17px 0;
    &.active {
      color: #202020;
      &::after {
        z-index: 2;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #202020;
      }
    }
  }
}
.styleTab {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  padding: 0 33px;
  border: 1px solid #d81921;
  font-weight: 600;
  font-size: 16px !important;
  color: #d81921;
  display: flex;
  align-items: center;
  &:hover {
    color: white;
    background: #d81921;
  }
  &.active {
    color: white;
    background: #d81921;
    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
    }
  }
}
.styleList {
  gap: 10px;
}
</style>